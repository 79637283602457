import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";

import { cn } from "@/lib/utils";

export default function Hero() {
  return (
    <section
      className={cn(
        'relative w-full h-[80vh] py-16 flex flex-col items-center justify-center',
        'lg:h-[75vh]',
        'text-center bg-primary/80',
      )}
    >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-45"
        style={{ backgroundImage: 'url(/images/capa-telas-animadas.gif)' }}
      />
      <div
        className={cn(
          'absolute inset-0 opacity-100',
          'bg-gradient-to-b from-[#6796FB] to-[#2757BF]',
          'mix-blend-multiply',
        )}
      />
      <div className="px-8 relative z-10 text-shadow-lg">
        <h1 className={cn(
          'mb-8 text-4xl text-white',
          'md:mb-10 md:text-5xl log:mb-12 lg:text-6xl ',
        )}>
          Vídeo-monitoramento e digitalização operacional
        </h1>
        <p className={cn(
          'text-lg text-white',
          'md:text-2xl lg:text-3xl',
        )}>
          Visualize, analise e gerencie frotas de veículos com precisão e eficiência.
        </p>
        <Link to="/">
          <Button className="mt-8 md:mt-12 lg:mt-16" variant="outline">
            Solicite uma demonstração
          </Button>
        </Link>
      </div>
    </section>
  );
};
