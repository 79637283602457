import { AccountInfo, Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

import { TenantEnum } from "@/data/enum/TenantEnum";
import { TenantDataType } from "@/models/TenantDataType";
import { AuthorizedStatusType } from "./models/AuthorizedStatusType";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
};

export const CustomerTenantVerify = async (
  tenantEnum: TenantEnum,
  account: AccountInfo | undefined
): Promise<AuthorizedStatusType> => {
  if (!account) return 'unauthorized';

  try {
    const instanceForTenant = await InstanceByClient(tenantEnum);

    try {
      await instanceForTenant.acquireTokenSilent({ account, scopes: [] });

      return 'authorized';
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        if (
          // AADSTS50058 - UserInformationNotProvided
          err.errorNo === '50058' ||
          // AADSTS50072 - UserStrongAuthEnrollmentRequiredInterrupt
          err.errorNo === '50072' ||
          // AADSTS50074 - UserStrongAuthClientAuthNRequiredInterrupt
          err.errorNo === '50074' ||
          // AADSTS50076 - UserStrongAuthEnrollmentRequiredInterrupt
          err.errorNo === '50076' ||
          // AADSTS50078 - UserStrongAuthClientAuthNRequired
          err.errorNo === '50078' ||
          // AADSTS50079 - UserStrongAuthEnrollmentRequired
          err.errorNo === '50079'
        ) {
          return 'incomplete';
        }
      }

      return 'unauthorized';
    }
  } catch (err) {
    console.error(err);

    return 'unauthorized';
  }
};

async function InstanceByClient(
  tenantEnum: TenantEnum,
): Promise<PublicClientApplication> {
  const { clientId, tenantId } = tenants.find(
    (tenant) => tenant.key === tenantEnum
  ) as TenantDataType;

  const instance = new PublicClientApplication({
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
      redirectUri: process.env.REACT_APP_FRONT,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    },
  });

  await instance.initialize();

  return instance;
}

export const tenants: TenantDataType[] = [
  {
    key: TenantEnum.PARTNERSHIP_MS,
    tenantName: 'Partnership MS',
    clientId: process.env.REACT_APP_CLIENT_ID_PTN_MS || '',
    tenantId: process.env.REACT_APP_TENANT_ID_PTN_MS || '',
    frontendUrl: process.env.REACT_APP_FRONT_PTN_MS || '',
    logo: 'images/logos/logo-partnership@2x-100.jpg',
  },
  {
    key: TenantEnum.PARTNERSHIP_PA,
    tenantName: 'Partnership PA',
    clientId: process.env.REACT_APP_CLIENT_ID_PTN_PA || '',
    tenantId: process.env.REACT_APP_TENANT_ID_PTN_PA || '',
    frontendUrl: process.env.REACT_APP_FRONT_PTN_PA || '',
    logo: 'images/logos/logo-partnership@2x-100.jpg',
  },
  {
    key: TenantEnum.PARTNERSHIP_MT,
    tenantName: 'Partnership MT',
    clientId: process.env.REACT_APP_CLIENT_ID_PTN_MT || '',
    tenantId: process.env.REACT_APP_TENANT_ID_PTN_MT || '',
    frontendUrl: process.env.REACT_APP_FRONT_PTN_MT || '',
    logo: 'images/logos/logo-partnership@2x-100.jpg',
  },
  {
    key: TenantEnum.PARTNERSHIP_GO,
    tenantName: 'Partnership GO',
    clientId: process.env.REACT_APP_CLIENT_ID_PTN_GO || '',
    tenantId: process.env.REACT_APP_TENANT_ID_PTN_GO || '',
    frontendUrl: process.env.REACT_APP_FRONT_PTN_GO || '',
    logo: 'images/logos/logo-partnership@2x-100.jpg',
  },
  {
    key: TenantEnum.EGP,
    tenantName: 'EGP',
    clientId: process.env.REACT_APP_CLIENT_ID_EGP || '',
    tenantId: process.env.REACT_APP_TENANT_ID_EGP || '',
    frontendUrl: process.env.REACT_APP_FRONT_EGP || '',
    logo: 'images/logos/logo-egp@2x-100.jpg',
  },
  {
    key: TenantEnum.EQUATORIAL_PA,
    tenantName: 'Equatorial PA',
    clientId: process.env.REACT_APP_CLIENT_ID_EQ_PA || '',
    tenantId: process.env.REACT_APP_TENANT_ID_EQ_PA || '',
    frontendUrl: process.env.REACT_APP_FRONT_EQ_PA || '',
    logo: 'images/logos/logo-equatorial@2x-100@2x.jpg',
  },
];
