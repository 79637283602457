import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./msalConfig";

import { Router } from "./routes/Router";

import { Toaster } from "@/components/ui/toaster";

const pca = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <MsalProvider instance={pca}>
      <BrowserRouter>
        <div>
          <Router />
          <Toaster />
        </div>
      </BrowserRouter>
    </MsalProvider>
  );
};
