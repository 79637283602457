import {
  ChartNoAxesCombinedIcon,
  MessageSquareWarningIcon,
  SlidersHorizontalIcon,
} from "lucide-react";

export default function FeaturesSection() {
  return (
    <section
      id="functions"
      className="py-16 px-6 sm:py-20 sm:px-8 lg:py-28 lg:px-16 xl:py-28 xl:px-44 bg-transparent"
    >
      <div className="max-w-[1440px] mx-auto px-4 lg:px-8">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl text-center text-white mb-12 sm:mb-16 lg:mb-48">
          Insights que fazem a diferença
        </h2>
        <div className="flex flex-col gap-16 sm:gap-20 lg:gap-24">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center">
            <div className="h-fit overflow-hidden rounded-2xl">
              <img
                src="/images/tela-dashboard.jpg"
                alt="dashboard"
              />
            </div>
            <div className="px-6 sm:px-12 py-8 sm:py-16 flex flex-col justify-center text-muted">
              <ChartNoAxesCombinedIcon className="block w-20 sm:w-24 h-20 sm:h-24 mb-4" />
              <h3 className="mb-4 text-xl sm:text-2xl font-semibold">Dashboards</h3>
              <p className="text-sm sm:text-base">
                Obtenha uma visão geral e detalhada de sua frota com dashboards
                personalizáveis que atendem às suas necessidades específicas.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center">
            <div className="px-6 sm:px-12 py-8 sm:py-16 flex flex-col justify-center text-muted">
              <SlidersHorizontalIcon className="block w-20 sm:w-24 h-20 sm:h-24 mb-4" />
              <h3 className="mb-4 text-xl sm:text-2xl font-semibold">Filtros Personalizados</h3>
              <p className="text-sm sm:text-base">
                Filtre dados rapidamente com opções personalizáveis, permitindo análises
                por períodos ou critérios específicos.
              </p>
            </div>
            <div className="h-fit overflow-hidden rounded-2xl">
              <img
                src="/images/tela-filtros.jpg"
                alt="dashboard"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center">
            <div className="h-fit overflow-hidden rounded-2xl">
              <img
                src="/images/tela-alertas.jpg"
                alt="dashboard"
              />
            </div>
            <div className="px-6 sm:px-12 py-8 sm:py-16 flex flex-col justify-center text-muted">
              <MessageSquareWarningIcon className="block w-20 sm:w-24 h-20 sm:h-24 mb-4" />
              <h3 className="mb-4 text-xl sm:text-2xl font-semibold">Alertas</h3>
              <p className="text-sm sm:text-base">
                Receba alertas proativos e automáticos sobre eventos críticos, como
                uso de celular ou comportamento de risco.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
