import { Route, Routes } from "react-router-dom";

import Home from "@/views/Landing";
import Login from "@/views/Login";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};
