import { Card, CardContent, CardDescription, CardTitle } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";

export default function FeatureSlider() {
  return (
    <section
      id="about"
      className={cn(
        'pt-32 pb-16 px-12',
        'lg:pt-52 lg:pb-28 lg:px-44',
        'bg-transparent text-white',
      )}
    >
      <h2 className={cn(
        'mb-16 text-4xl text-center font-semibold',
        'lg:text-5xl lg:mb-20',
      )}>
        Tecnologia que transforma dados em decisões inteligentes
      </h2>
      <div className="px-0 relative">
        <Carousel
          className="w-full px-6 sm:px-12 lg:px-12"
          opts={{ align: 'start' }}
        >
          <CarouselContent className="items-stretch">
            <CarouselItem className="basis-1/2 lg:basis-1/3">
              <Card className="h-full overflow-hidden bg-black border-muted-foreground">
                <CardContent className="p-0 text-white">
                  <div
                    className="w-full aspect-video bg-cover bg-center border-b border-muted-foreground"
                    style={{ backgroundImage: 'url(images/capa-frota.svg)' }}
                  />
                  <div className="p-7 flex flex-col justify-center items-center text-center">
                    <CardTitle className="mb-5 text-xl sm:text-2xl lg:text-2xl font-semibold">
                      Lista de veículos
                    </CardTitle>
                    <CardDescription className="text-base lg:text-base text-white">
                      Controle completo de frotas com dados detalhados dos veículos
                      e uso de visão computacional para detectar desvios em serviços
                      e operações.
                    </CardDescription>
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
            <CarouselItem className="basis-1/2 lg:basis-1/3">
              <Card className="h-full overflow-hidden bg-black border-muted-foreground">
                <CardContent className="p-0 text-white">
                  <div
                    className="w-full aspect-video bg-cover bg-center border-b border-muted-foreground"
                    style={{ backgroundImage: 'url(images/capa-dashboard.svg)' }}
                  />
                  <div className="p-7 flex flex-col justify-center items-center text-center">
                    <CardTitle className="mb-5 text-xl sm:text-2xl lg:text-2xl font-semibold">
                      Gráficos Interativos
                    </CardTitle>
                    <CardDescription className="text-base lg:text-base text-white">
                      Visualize gráficos com dados de horas de serviço e deslocamento, em uma
                      visão geral ou por base específica, com filtros de tempo.
                    </CardDescription>
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
            <CarouselItem className="basis-1/2 lg:basis-1/3">
              <Card className="h-full overflow-hidden bg-black border-muted-foreground">
                <CardContent className="p-0 text-white">
                  <div
                    className="w-full aspect-video bg-cover bg-center border-b border-muted-foreground"
                    style={{ backgroundImage: 'url(images/capa-video-player.svg)' }}
                  />
                  <div className="p-7 flex flex-col justify-center items-center text-center">
                    <CardTitle className="mb-5 text-xl sm:text-2xl lg:text-2xl font-semibold">
                      Videoplayer Inteligente
                    </CardTitle>
                    <CardDescription className="text-base lg:text-base text-white">
                      Visualize as gravações dos veículos com alertas, destacando serviços e
                      problemas no momento exato de ocorrência.
                    </CardDescription>
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious className="left-2 text-black disabled:invisible" />
          <CarouselNext className="right-2 text-black disabled:invisible" />
        </Carousel>
      </div>
    </section>
  );
};
