import { Loader2Icon } from "lucide-react";

interface LoaderProps {
  loading?: boolean;
};

function Loader({ loading = true }: LoaderProps) {
  return !!loading ? (
    <div className="w-full h-screen fixed top-0 left-0 z-50 flex justify-center items-center bg-foreground/90">
      <Loader2Icon className="size-12 animate-spin text-accent" />
    </div>
  ) : null;
};

export default Loader;
