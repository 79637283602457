import { Link } from "react-router-dom";
import { InstagramIcon, LinkedinIcon, TwitterIcon } from "lucide-react";
import { cn } from "@/lib/utils";

const links = [
  { to: '/', title: 'Política de Privacidade' },
  { to: '/', title: 'Termos e Condições' },
  { to: '/', title: 'Contatos' },
];

const social = [
  { to: '/', title: <TwitterIcon /> },
  { to: 'https://www.linkedin.com', title: <LinkedinIcon /> },
  { to: 'https://www.instagram.com', title: <InstagramIcon /> },
];

export default function Footer() {
  return (
    <footer
      id="footer"
      className={cn(
        'sticky top-0 z-30 flex justify-between items-center gap-4',
        'py-8 px-16 lg:py-10 lg:px-20 xl:py-14 px-28',
        'bg-primary text-white',
      )}
    >
      <Link
        to="/"
        className="font-bold text-lg lg:text-xl xl-text-2xl"
      >
        DFU2RE
      </Link>
      <nav
        className={cn(
          'w-fit flex-col items-center text-lg font-medium',
          'md:flex md:flex-row md:items-center md:text-sm',
        )}
      >
        <span>&nbsp;</span>
        {links.map((item, index) => (
          <Link
            key={`nav-${index}`}
            className={cn(
              'h-fit hidden transition-colors cursor-pointer text-base',
              'py-2.5 px-5',
              'md:block lg:text-lg',
              'hover:text-foreground',
            )}
            to={item.to}
          >
            {item.title}
          </Link>
        ))}
      </nav>
      <div className="w-fit flex flex-nowrap gap-4">
        {social.map((item, index) => (
          <Link
            key={`footer-social-${index}`}
            className="text-white text-sm transition-colors hover:text-muted hover:underline"
            to={item.to}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </footer>
  );
};
