import { useEffect, useState } from "react";
import { ServerIcon } from "lucide-react";
import { useMsal } from "@azure/msal-react";

import Loader from "@/components/Loader";
import { Card, CardContent } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";

import { CustomerTenantVerify, tenants } from "@/msalConfig";
import { TenantEnum } from "@/data/enum/TenantEnum";
import { AuthorizedTenantsType } from "@/models/AuthorizedTenantsType";
import { cn } from "@/lib/utils";

export default function Login() {
  const { instance, accounts, inProgress } = useMsal();

  const { toast } = useToast()

  const [checkedTenants, setCheckedTenants] = useState<AuthorizedTenantsType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const checkAuthentication = async () => {
      if (inProgress === 'none' && accounts?.length === 0) {
        try {
          setLoading(true);

          await instance.loginRedirect({ scopes: [] });
        } catch (err) {
          console.error(err);

          if (isMounted) {
            setLoading(false);
          }
        }
      } else if (accounts.length > 0) {
        await silentLogin();
      }
    };

    const silentLogin = async () => {
      try {
        const authorizedList: AuthorizedTenantsType[] = [];
        const response = await instance.acquireTokenSilent({
          scopes: [], 
          account: accounts[0],
        });

        if (response?.account) {
          for (const tenantKey of Object.values(TenantEnum)) {
            const current = tenants.find((tenant) => tenant.key === tenantKey);
            const authorized = await CustomerTenantVerify(
              tenantKey,
              response.account,
            );

            if (current) {
              authorizedList.push({
                name: current.tenantName,
                href: current.frontendUrl,
                status: authorized,
                logo: current?.logo || '',
              });
            }
          }
        }

        if (isMounted) {
          setCheckedTenants(authorizedList);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    checkAuthentication();

    return () => {
      isMounted = false;
    };
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    const authorizedTenants = checkedTenants.filter(
      (tenant) => tenant.status === 'authorized',
    );
    const unauthorizedTenants = checkedTenants.filter(
      (tenant) => tenant.status === 'unauthorized',
    );

    if (
      !!toast &&
      !loading &&
      !!checkedTenants?.length &&
      Object.values(TenantEnum).length === checkedTenants?.length
    ) {
      if (authorizedTenants?.length === 1) {
        window.location.href = authorizedTenants[0].href;
      } else if (
        !!unauthorizedTenants?.length &&
        unauthorizedTenants?.length === checkedTenants?.length
      ) {
          toast({
            variant: "destructive",
            description: "Você não tem acesso a nenhum servidor.",
          });
        }
    }
  }, [checkedTenants, inProgress, loading, toast]);

  return (
    <main className="w-full min-h-screen max-w-[1440px] mx-auto p-8">
      <div className="space-y-4">
        <h1 className="text-3xl font-semibold">
          <span className="text-primary">D</span>FU2RE
        </h1>
        {!!checkedTenants.length && !loading ? (
          <p className="text-muted-foreground">Selecione o servidor:</p>
        ) : null}
      </div>
      {!!checkedTenants.length ? (
        <div className="mt-12 grid grid-cols-2 gap-4 max-w-[768px] mx-auto lg:grid-cols-3">
          {checkedTenants.map(({ name, href, status, logo }) => status !== 'unauthorized' && (
            <a
              className="block w-full h-full"
              key={`${name}-${href}`}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardContent className="relative py-8">
                  {!!logo?.length ? (
                    <div
                      className="w-[88px] aspect-square rounded-full mb-4 mx-auto"
                      style={{
                        backgroundImage: `url(${logo})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                  ) : (
                    <div className={cn(
                      'rounded-full w-fit p-5 mx-auto mb-4',
                      status === 'authorized' && 'bg-primary',
                      status === 'incomplete' && 'bg-amber-400',
                    )}>
                      <ServerIcon className="text-secondary" size={48} />
                    </div>
                  )}
                  <p className="text-sm font-bold text-center">{name}</p>
                  <p className="absolute bottom-4 left-1/2 -translate-x-1/2 w-full text-xs text-center text-destructive">
                    {status === 'incomplete' ? 'Verificação 2FA pendente' : <>&nbsp;</>}
                  </p>
                </CardContent>
              </Card>
            </a>
          ))}
        </div>
      ) : null}
      <Loader loading={loading} />
    </main>
  );
};
