import { Button } from "@/components/ui/button";

export default function Highlight() {
  return (
    <section className="py-20 px-6 bg-muted lg:py-56 lg:px-12">
      <div className="max-w-[768px] mx-auto text-center">
        <h2 className="mb-5 text-3xl lg:text-5xl text-primary font-semibold">
          Por que o DFU2URE?
        </h2>
        <p className="mb-8 text-lg lg:text-2xl">
          Aumente a segurança, reduza custos e otimize a gestão de operações com
          frotas de veículos de serviço, utilizando nossa solução de visão computacional.
          A ferramenta disponibiliza vídeos das operações, com alertas de problemas e
          informações sobre os serviços realizados.
        </p>
        <Button className="text-base lg:text-lg">
          Experimente nossa plataforma agora!
        </Button>
        <p className="py-12 text-xs lg:text-sm text-muted-foreground">
          Aqui entra mais algum texto com menos destaque, mas que seja importante para
          entrar nesta página também. Aqui entra mais algum texto com menos destaque,
          mas que seja importante para entrar nesta página também. Aqui entra mais
          algum texto com menos destaque, mas que seja importante para entrar nesta
          página também. 
        </p>
      </div>
    </section>
  );
};
