import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";

import { cn } from "@/lib/utils";

const menuItems = [
  { to: 'about', title: 'Sobre' },
  { to: 'functions', title: 'Funcionalidades' },
  { to: 'footer', title: 'Contato' },
];

export default function NavBar() {
  const handleScroll = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={cn(
      'sticky top-0 z-30 flex justify-between items-center gap-4',
      'py-8 px-16 lg:py-10 lg:px-20 xl:py-14 px-28',
      'bg-background border-b',
    )}>
      <Link
        to="/"
        className={cn(
          'font-bold',
          'text-lg lg:text-xl xl-text-2xl',
        )}
      >
        <span className="text-primary">D</span>
        FU
        <span className="text-primary">2</span>
        RE
      </Link>
      <nav
        className={cn(
          'w-fit flex-col items-center text-lg font-medium',
          'md:flex md:flex-row md:items-center md:text-sm',
        )}
      >
        <span>&nbsp;</span>
        {menuItems.map((item, index) => (
          <Button
            key={`nav-${index}`}
            className={cn(
              'h-fit hidden transition-colors cursor-pointer text-base',
              'py-2.5 px-5',
              'md:block lg:text-lg',
              'hover:text-foreground',
            )}
            variant="ghost"
            onClick={() => handleScroll(item.to)}
          >
            {item.title}
          </Button>
        ))}
      </nav>
      <Button asChild>
        <Link to="/login" className="block">Login</Link>
      </Button>
    </header>
  );
};
