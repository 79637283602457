import Hero from "@/components/Hero";
import FeatureSlider from "@/components/FeatureSlider";
import Features from "@/components/Features";
// import Demonstration from "@/components/Demonstration";
import Highlight from "@/components/Highlight";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default function Home() {
  return (
    <>
      <main className="w-full min-h-screen">
        <NavBar />
        <Hero />
        <div 
          style={{
            backgroundImage: 'url(/images/fundo-colorido.svg)',
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundColor: 'black',
          }} 
          className="w-full"
        >
          <FeatureSlider />
          <Features />
        </div>
        {/* <Demonstration /> */}
        <Highlight />
        <Footer />
      </main>
    </>
  );
};
